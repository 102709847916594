<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="tourism-search__item col-lg-3">
                        <div class="form-group">
                            <label class="form-title">Країни подорожі</label>
                            <multiselect
                                :class="['form-control  tourism-search-select',  {invalid: ((!$v.country.required) && submitCheck)}]"
                                v-model="country"
                                :options="countries"
                                placeholder="Наприклад США"
                                :allow-empty="false"
                                label="name"
                                :loading="true"
                            >
                                <template slot="option" slot-scope="{ option }">
                                    <span v-if="typeof option.label === 'string' && typeof option.name !== 'undefined'">
                                        <strong>{{option.name}}: </strong>
                                        <span>{{option.label}}</span>
                                    </span>
                                    <span v-else-if="option.label && typeof option.label !== 'string'">
                                        <strong>{{option.name}}</strong>
                                    </span>
                                    <span v-else-if="!option.label">
                                        {{option.name}}
                                    </span>
                                    <span v-else>
                                        {{option.label}}
                                    </span>
                                </template>
                            </multiselect>
<!--                            <span v-if="errors.has('country')" class="errors">Вкажіть країну поїздки</span>-->
                            <div>
                                <span v-for="(item, index) in popularFourCountries">
                                    <a href="#" @click.prevent="choseCountry(item)" class="link" v-text="item.name"></a>
                                    <span v-if="index !== (popularFourCountries.length - 1)">, </span>
                                </span>
                            </div>
                            <small  class="error"  v-if="!$v.country.required && submitCheck">
                                        {{ 'Вкажіть країну' }}
                                    </small>
                        </div>
                    </div>
<!--                    <div class="tourism-search__item col-lg-3 col-sm-4">
                        <div class="fade-out" :class="{'fade-in' : show_humans}"
                             @click.capture="show_humans = !show_humans">
                        </div>
                        <div class="container-show-humans form-group d-flex flex-column flex-grow-1">
                            <label class="form-title">Кількість туристів</label>
                            <div :class="[/*{'has_error':errors.has('count_humans')},*/ {'active-input': show_humans}]"
                                 class="form-control d-flex align-items-center position-relative multiselect"
                                 @click="show_humans = !show_humans">
                                <span class="custom-select__text">{{count_humans}}</span>
                                <div class="multiselect__select"
                                     :class="[{'multiselect__arrow': show_humans}]"> </div>
                            </div>
                            <transition-group name="menu-humans-fade">
                                <div class="container-humans" v-if="show_humans" :key="15996">
                                    <div :key="item.id" class="quantity d-flex justify-content-between" v-for="(item, index) in humans">
                                        <label :key="item.id +10" class="quantity-age">{{item.name}} </label>
                                        <div :key="item.id + 20" class="quantity-block d-flex align-items-center justify-content-center">
                                            <button :key="item.id + 30" class="quantity-arrow-minus" @click.self="quantityMinus(index)" :disabled="count_humans === 1 || item.value == 0"> - </button>
                                            <input :key="item.id + 40" class="quantity-num" type="number" v-model="item.value" />
                                            <button :key="item.id + 50" class="quantity-arrow-plus" @click.self="quantityPlus(index)"> + </button>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </div>
                    </div>-->

                        <div  class="tourism-search__item col-lg-3 col-sm-4">
                            <div class="container-show-humans form-group d-flex flex-column flex-grow-1" @click="togglePopup" ref="multiselect">
                                <label>Кількість та вік туристів</label>

                                <div :class="['form-control  align-items-center position-relative multiselect multiselect_block',  {invalid: ((!$v.clientList.required || !$v.clientList.Validate) && submitCheck)}]">
                                      <span v-if="selectedClients.length > 0" v-for="(client, index) in selectedClients" :key="index" class="selected-client">
                                       {{ formatDate(client) }}
                                        <span class="remove-button" @click.stop="removeSelectedClient(index)"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.9214L10.7879 0.999865" stroke="#0076B2" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M1.2121 1.07861L11 11.0001" stroke="#0076B2" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg></span>
                                      </span>
                                    <span class="client_pleaseholder"  v-if="selectedClients.length == 0">
                                        {{ 'Вкажіть кількість та вік' }}
                                    </span>
                                    <div class="multiselect__select" :class="{'multiselect__arrow': popupVisible}" @click ="handleArrowClick"></div>
                                </div>
                                <small class="error" v-if="(!$v.clientList.required || !$v.clientList.Validate) && submitCheck">Bкажіть хоча б одну людину</small>
                            </div>

                            <div class="popup datapicker-mask" :style="{ width: popupWidth + 'px' }" v-if="popupVisible" ref="popup"  @click="handlePopupClick">
                                <div v-for="(client, index) in clientList" :key="index" class="client-row">
                                    <span class="client_number"><b>Учасник {{ index + 1 }}</b></span>
                                    <date-picker
                                        name="start_date"
                                        v-model="client.birthDate"
                                        @input="updateSelectedClients"
                                        :config="optionsBirthDates"
                                        :class="['date-input__dp']"
                                        placeholder="Дата народження"
                                    >
                                        <template #input>
                                            <input
                                                type="text"
                                                class="form-control date-input__dp"
                                                placeholder="Дата народження"
                                            >
                                        </template>
                                    </date-picker>

                                    <button @click="removeClient(index)" class="remove-button"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.9214L10.7879 0.999865" stroke="#0076B2" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M1.2121 1.07861L11 11.0001" stroke="#0076B2" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                    </button>
                                </div>
                                <a v-if="canAddClient" @click="addClient" class="add-client"><b>+ Додати ще учасника</b></a>
                            </div>
                        </div>




                    <div class="col-lg-3 col-sm-4">
                        <div class="form-group datapicker-mask" ref="start_date">
                            <div>
                                <label class="form-title">Коли виїжджаєте</label>
                                 <date-picker
                                    name="start_date"
                                    v-model="start_date"
                                    :config="optionsDate"
                                    :class="['date-input__dp']"
                                    placeholder="Від’їзд">
                                    <template #input>
                                        <input
                                            type="text"
                                            class="form-control date-input__dp"
                                            placeholder="Від’їзд"
                                        >
                                    </template>
                                </date-picker>
                            </div>
                            <div v-if="showYearProgram" class="form-group form-check mt-0">
                                <input class="form-check-input" type="checkbox" v-model="yearProgram" id="yearCheck">
                                <label class="form-check-label font-weight-normal" for="yearCheck">
                                    Програма на рік
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-4"  >
                        <div  class="form-group">
                            <label class="form-title">Період дії</label>
                            <input class="form-control" v-model="periodOption" v-on:keypress="LetterNumMask($event)" type="number"  placeholder="Кількість днів">
                        </div>
                    </div>
<!--                    <div class="col-lg-6" v-else>
                        <div class="form-group">
                            <label>Період дії</label>
                            <multiselect
                                :class="['form-control', {'disabledLastOption': userType && userType.id == 3}]"
                                v-model="periodOption"
                                :options="periodOptions"
                                :max-height="120"
                                label="name"
                                :allow-empty="false"
                                placeholder="Виберіть період дії"
                                :searchable="false"></multiselect>
                        </div>
                    </div>-->
                </div>
            </div>
            <div class="col-12 submit">
                <button type="button" class="btn btn-blue mt-auto tourism-tab-content__submit w-100 mb-5" @click.prevent="setSearchParams">Показати результати</button>
            </div>
        </div>
    </div>
</template>
<style >
.client_pleaseholder{
    display: block;
    position: relative;
    margin-top: 9px;
    margin-left: 10px;
    color: #ACAFB6;
}
.client_number{
    margin-right: 10px;
    width: 124px;
}
.client_data{
    width: 100%;
}
.selected-client {
    display: inline-block;
    background-color: #BFDDEC;
    border-radius: 25px!important;
    color: #0076B2;
    font-size: 14px;
    padding: 0px 14px;
    margin-right: -2px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-top: 7px;
    position: relative;
    z-index: 2;
}

.remove-button {
    margin-left: 4px;
    cursor: pointer;
}
.multiselect_block{
/*    padding-right: 12px!important;
    padding-left: 5px!important;*/
    height: auto;
    /*padding-top: 5px!important;*/
}
.multiselect_block .multiselect__arrow{
    background: transparent!important;
}
.popup {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 999;
    box-shadow: 0px 0px 12px 0px rgba(100, 100, 100, 0.5);
    border-radius: 3px;
}

.client-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.remove-button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
}

.add-client {
    display: block;
    margin-top: 10px;
    cursor: pointer;
    width: 170px;
}





.multiselect__arrow {
    transition: all .2s;
    transform: rotate(180deg);
    right: 5px !important;
}
.active-input {
    border-color: #ffad3d;
}
.custom-select__text {
    padding-left: 15px;
    padding-right: 15px;
}
.real_checkbox{
    display: none;
}
.fake_checkbox{
    border: solid #dde2e5 1px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    flex-shrink: 0;
    color: white;
}
.fake_checkbox.checked{
    border: solid #ffad3d 2px;
    color: #ffad3d;
}
.fake_checkbox:after {
    content: "";
    background-color: transparent;
    position: absolute;
    right: 19px;
    top: 15px;
    width: 5px;
    border-bottom: 2px solid #fb9b13;
    height: 12px;
    border-right: 2px solid #fb9b13;
    transform: rotate(45deg);
    display: inline-block;
    opacity: 0;
    transition: all 0.2s;
}
.fake_checkbox.checked:after {
    opacity: 1;
}
.fake_checkbox-text {
    margin-top: 4px;
}
.errors{
    color:red;
}
.has_error{
    border-color: red;
}
.selectors.tourism-select .results .result-item .result-item__logo img {
    max-width: 80%;
    max-height: 35px;
    height: auto;
}
.container-show-humans, .container-show-risks {
    position: relative;
}
.container-humans {
    position: absolute;
    top: 97px;
    right: 0;
    z-index: 3;
    max-width: 320px;
    width: 320px;
    box-shadow: 0px 0px 12px 0px rgba(100, 100, 100, 0.5);
    padding: 10px;
    background-color: white;
}
.menu-humans-fade-enter-active, .menu-humans-fade-leave-active,
.menu-risks-fade-enter-active, .menu-risks-fade-leave-active {
    transition: all .2s ease
}
.menu-humans-fade-enter, .menu-humans-fade-leave-to,
.menu-risks-fade-enter, .menu-risks-fade-leave-to {
    transform: translateY(-20px);
    z-index: -1;
    opacity: 0;
}
.label_show_humans{
    cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.quantity {
    padding: 9px;
}
.quantity-age {
    font-size: 16px;
    font-weight: normal;
    width: 90px;
    line-height: 1.3;
}
.quantity-block {
    font-size: 16px;
    text-align: center;
    border: 2px solid lightgrey;
    width: 135px;
    height: 37px;
}
.quantity-arrow-minus,
.quantity-arrow-plus {
    background-color: white;
    cursor: pointer;
    font-size: 30px;
    width: 40px;
    box-sizing: border-box;
    border: none;
    outline: none;
    color: #009beb;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quantity-arrow-minus:disabled,
.quantity-arrow-plus:disabled {
    color: lightgrey;
}
.quantity-arrow-plus {
    color: #009beb;
    padding: 9px 5px 5px;
}
.quantity-num {
    font-size: 16px;
    padding: 5px 10px;
    border: none;
    outline: none;
    max-width: 40px;
    text-align: center;
    vertical-align: middle;
    height: 32px;
}
.fade-out {
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    display: none;
}
.fade-in {
    display: block;
    z-index: 2;
}
.tourism-search {
    padding: 0;
}
.tourism-search__items {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}
.tourism-search__country {
    flex-grow: 2;
    width: 40%;
}
.tourism-search__date {
    max-width: 35%;
    padding-left: 20px;
    padding-right: 20px;
}
.tourism-search__date-inputs {
    display: flex;
}
.tourism-search__date-input:first-of-type {
    margin-right: 10px;
}
.date-input__dp {
    min-height: 42px;
    background-image: url(~@/assets/images/icons/date-icon.svg);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: right 11px bottom 11px;
}
.tourism-search__purpose, .tourism-search__limit {
    width: 50%;
    padding-right: 20px;
}
.tourism-search__risk {
    width: 50%;
}
.container-risks {
    position: absolute;
    top: 97px;
    right: 0;
    z-index: 3;
    width: 100%;
    box-shadow: 0px 0px 12px 0px rgba(100, 100, 100, 0.5);
    padding: 10px;
    background-color: white;
    line-height: 1;
}
.tourism-tab-content__submit {
    min-height: 42px;
}
@media (max-width: 991px) {
    .tourism-search__country {
        width: 100%;
    }
    .tourism-search__items {
        flex-direction: column;
    }
    .tourism-search__date {
        max-width: 100%;
        width: 100%;
        padding: 0;
    }
    .tourism-search__date-inputs {
        display: block;
    }
    .tourism-search__date-input:first-of-type {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .container-humans {
        right: unset;
        width: 100%;
        max-width: unset;
    }
    .tourism-search__purpose, .tourism-search__limit, .tourism-search__risk {
        width: 100%;
        padding-right: 0px;
    }
}
</style>
<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'
import {getByParams as getInsuredByParams} from "@/api/insured";
import {maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
import IMask from 'imask';
import Vue from 'vue';

export default {
    components: { Multiselect },
    props: ['dataCountry', 'dataStart', 'dataEnd', 'dataPeriodOption', 'dataYearProgram', 'dataHumans', 'dataFilters', 'dataBirthDates'],
    data: function () {
        return {
            selectedClients: [],
            clientList: [],
            popupVisible: false,
            maxTourists: 10,
            popupWidth: 0,

            showYearProgram: false,
            purpose : [
                {value: "no_activities", name: "Без активності"},
                {value: "activities", name: "Активне проведення часу"},
                {value: "amateur_sport", name: "Аматорський спорт"}],
            risks:[
                {id: 1,  name: 'Медичнi витрати'},
                {id: 2,  name: 'Нещасний випадок'},
                {id: 3,  name: 'Цивільно-правова відповідальність перед третіми особами'},
                {id: 4,  name: 'Відшкодування витрат при втраті багажу'},
                {id: 5,  name: 'Неможливість поїздки'},
                {id: 6,  name: 'Неотримання візи'},
                {id: 7,  name: 'Фінансові ризики під час поїздкии'},
                {id: 8,  name: 'Добровільне медичне страхування'},
                {id: 12, name: 'Затримка авіарейсу'},
                {id: 35, name: 'Страхування майна'},
            ],
            periodOption: 10,
            errors : {},
            show_risks: false,
            show_humans:false,
            submitCheck:false,
            disabled:false,
            focusedPriceDiapason: false,
            bool: true,
            humans: [
                {name: 'Діти до 1 року ', value: 0, min: 0, max: 1, birthDates: [], id:1},
                {name: 'Діти до 3 років ', value: 0, min: 2, max: 3,  birthDates: [], id:2},
                {name: 'Підлітки до 18 років ', value: 0, min: 4, max: 18,  birthDates: [], id:3},
                {name: 'Дорослі до 69 років ', value: 0, min: 19, max: 69, birthDates: [""], id:4},
                {name: 'Дорослі 70-75 років ', value: 0, min: 70, max: 75, birthDates: [], id:5},
                {name: 'Літні 76-100 років ', value: 0, min: 76, max: 100, birthDates: [], id:6}
            ],
            yearProgram: false,
            start_date: moment().add(1, 'day').format('DD.MM.YYYY'),
            end_date: moment().add(10, 'day').format('DD.MM.YYYY'),
            filters: {
                franchise: '',
                options_list: [],
                activities: {name: "Без активності", value: 'no_activities'},
                activitiesItem: 'Без активностей',
            },
            countries: [],
            country: null,
            mainCountries: [
                { label: 'Популярнi напрямки', $isDisabled: true },
                { label: 'Всi напрямки', $isDisabled: true }
            ],
            franchises:[ 10000, 15000, 30000, 50000, 100000 ],
            touristsBirthDates: [],
            items:[],
            optionsDate: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                minDate: new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 1)),
                locale: 'ru',
                icons: {
                    previous: 'icon arrow-point-to-left',
                    next: 'icon arrow-point-to-right',
                },
            },
            optionsBirthDates: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                maxDate: new Date(),
                minDate: moment().subtract(100, 'years').subtract(1,'days').toDate(),
                locale: 'ru',
                icons: {
                    previous: 'icon arrow-point-to-left',
                    next: 'icon arrow-point-to-right',
                },
            },
        };
    },
    validations: {
        clientList:{ required, Validate(value) {
                 if(value[0] !== undefined && value[0].birthDate == null){
                     return false;
                 }else{
                     return true;
                 }
            } },
        country:{ required },
    },
    created() {
        Date.prototype.addDays = function(n) {
            this.setDate(this.getDate() + n);
        };
    },
    mounted: function () {
        this.setDataFromProps();
        if(!this.dataBirthDates) {
            this.addClient();
        }
        this.fetchCountries();
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.setPopupWidth);
        this.updateSelectedClients();

        this.datapickerMask();
    },
    computed: {
        canAddClient() {
            return this.clientList.length < this.maxTourists;
        },
        count_humans: function (){
            var count = 0;
            this.humans.forEach(item => {
                if (item.value > 0) {
                    count = count + item.value;
                }
            })
            /*if(count){
                this.errors.remove('count_humans');
            }*/
            return count;
        },
        popularFourCountries: function () {
            let popular = ['Весь світ', 'Європа'];
            return this.countries.filter((country) => {
                return popular.includes(country.name);
            });
        },
 /*       daysLong :function (){
            var start = this.parseDate(this.start_date);
            var end = this.parseDate(this.end_date);
            return this.datediff(start, end);
        },*/
        birthDatesArray() {
            const array = [];
            this.humans.forEach(item => {
                if (item.value > 0) {
                    array.push(...item.birthDates);
                }
            });
            return array;
        }
    },
    beforeDestroy() {
        window.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener('resize', this.setPopupWidth);
    },
    watch:{
        popupVisible(value) {
            if (value) {
                window.addEventListener('click', this.handleClickOutside);
            } else {
                window.removeEventListener('click', this.handleClickOutside);
            }
        },
        "yearProgram":  function(newValue, oldValue) {
            let end_date = moment(this.dataStart || this.start_date, 'DD.MM.YYYY');
            if (newValue) {
                end_date.add(1, 'year').subtract(1,'days');
            } else {
                end_date.add(this.periodOption, 'days').subtract(1,'days');
            }
            this.end_date = end_date.format('DD.MM.YYYY');
        },
        "periodOption": function (newValue, oldValue) {
            /*if (this.yearProgram) {
                const result = Math.min(Math.ceil(newValue / 30) * 30, 365);
                this.periodOption = result;
            }*/
            this.end_date = moment(this.dataStart || this.start_date, 'DD.MM.YYYY')
                .add(this.periodOption, 'days')
                .subtract(1,'days')
                .format('DD.MM.YYYY');
        },
      "start_date":function (newValue, oldValue){
        this.end_date = moment(newValue, 'DD.MM.YYYY')
            .add(this.periodOption, 'days')
            .subtract(1,'days')
            .format('DD.MM.YYYY');
      }
    },
    methods: {
        togglePopup(event) {
            event.stopPropagation();
            this.popupVisible = !this.popupVisible;
            this.setPopupWidth();
        },
        handleArrowClick(event) {
          this.togglePopup();
        },
        addClient() {
            if (this.canAddClient) {
                this.clientList.push({ birthDate: null });
            }
            this.updateSelectedClients();
        },
        setPopupWidth() {
            const multiselectWidth = this.$refs.multiselect.offsetWidth;
            this.popupWidth = multiselectWidth;
        },
        removeClient(index) {
            this.clientList.splice(index, 1);
            this.updateSelectedClients();
        },
        updateSelectedClients() {
            this.selectedClients = this.clientList
                .filter(client => client.birthDate !== null)
                .map(client => client.birthDate)
                .filter(date => date !== null);
            this.humans.forEach(human => {
                human.value = 0;
                human.birthDates = [];
            });

            this.selectedClients.forEach(date => {
                const birthDate = moment(date, 'DD.MM.YYYY');
                const age = moment().diff(birthDate, 'years');
                this.humans.forEach(human => {
                    if (age >= human.min && age <= human.max) {
                        human.value++;
                        human.birthDates.push(date);
                    }
                });
            });
        },
        removeSelectedClient(index) {
            this.clientList.splice(index, 1);
            this.updateSelectedClients();
        },
        formatDate(date) {
            return date ? moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') : '';
        },
        handleClickOutside(event) {

            const multiselectElement = this.$el.querySelector('.multiselect')  || null;
            const popupElement = this.$refs.popup;
            const isInsideMultiselect = multiselectElement && (multiselectElement.contains(event.target) || multiselectElement === event.target);
            const isInsidePopup = popupElement && (popupElement.contains(event.target) || popupElement === event.target);

            // Проверяем, что клик произошел вне попапа и не на стрелочке псевдо-мультиселекта
            const isInsideArrow = event.target.classList.contains('multiselect__select');
            if (!isInsideMultiselect && !isInsidePopup && !isInsideArrow) {
              this.popupVisible = false;
            }

            this.datapickerMask();
        },
        datapickerMask(){
            const inputElements = document.querySelectorAll('.datapicker-mask input.form-control.date-input__dp');

            if(inputElements){
                inputElements.forEach((inputElement, index) => {
                    IMask(inputElement, {
                        mask: '00.00.0000'
                    });
                });
            }
        },
        handlePopupClick(event) {
            event.stopPropagation();
        },
        setDataFromProps() {
            this.yearProgram = this.dataYearProgram ?? this.yearProgram;
            this.periodOption = this.dataPeriodOption ?? this.periodOption;
            this.start_date = this.dataStart ?? this.start_date;
            this.end_date = moment(this.dataStart || this.start_date, 'DD.MM.YYYY')
                .add(this.periodOption, 'days')
                .subtract(1,'days')
                .format('DD.MM.YYYY');
            let birthDatesArray = this.dataBirthDates || this.birthDatesArray;
            birthDatesArray.forEach(item => {
                if (item) {
                    this.clientList.push({ birthDate: item });
                }
            });
            this.humans = this.dataHumans || this.humans;
            this.filters = this.dataFilters ?? this.filters;
        },
        checkResultTourismRoute() {
            return this.$router.currentRoute.name === 'tourismResult';
        },
        goToStep(){
            this.$parent.step = 1;
        },
        toggleActivities(val){
            this.filters.activitiesItem = val;
        },
        is_checked(risk){
            let ids = _.map(this.filters.options_list, 'id')
            return ids.includes(risk.id);
        },
        toggleOptions(risk){
            let ids = _.map(this.filters.options_list, 'id')
            if(ids.includes(risk.id)){
                const index = _.findIndex(this.filters.options_list, (element) => {
                    return element.id == risk.id
                })
                this.filters.options_list.splice(index, 1);
            }else{
                this.filters.options_list.push(risk);
            }
        },
        quantityPlus(index) {
            this.humans[index].value = this.humans[index].value + 1;
        },
        quantityMinus(index) {
            if(this.humans[index].value > 0){
                this.humans[index].value = this.humans[index].value - 1;
            }
        },
        updateHumans(date, index) {
            const birthDate = moment(date, 'YYYY-MM-DD');
            const age = moment().diff(birthDate, 'years');
            const human = this.humans[index];

            if (age >= human.min && age <= human.max) {
                if (!human.birthDates.includes(date)) {
                    human.value++;
                    human.birthDates.push(date);
                }
            } else {
                this.removeBirthDate(date, index);
            }
        },
        removeBirthDate(date, index) {
            const human = this.humans[index];
            const birthIndex = human.birthDates.indexOf(date);

            if (birthIndex !== -1) {
                human.value--;
                human.birthDates.splice(birthIndex, 1);
            }
        },
        choseCountry(value) {
            this.country = value;
        },
        datediff(first, second){
            return Math.round((second-first)/(1000*60*60*24));
        },
        parseDate(date){
            var start_str = date.split('.');
            return new Date(start_str[2], start_str[1]-1, start_str[0])
        },
        setSearchParams(){
            this.submitCheck = true;

            if(this.$v.$invalid){
                this.$v.$touch()
                return
            }

            const params = {
                date: this.start_date,
                end: this.end_date,
                days: parseInt(this.periodOption),
                country: this.country,
                tourists: this.count_humans,
                humans: this.humans,
                yearProgram: this.yearProgram,
                birthDates: this.birthDatesArray,
                filters: this.filters,
                activities: this.filters.activities.value
            };

            this.$store.commit('SET_SEARCH_TOURISM_PARAMS', params);
            if (this.checkResultTourismRoute()) {
                this.$parent.showTourismSearchForm = !this.$parent.showTourismSearchForm
                return
            } else {
                this.$router.push({name: `tourismResult`});
            }
        },
        getShengen () {
            return _.findLast(this.countries, (country)=>
                country.name === "Шенгенські країни"
            )
        },
        fetchCountries() {
            axios.get('/api/countries/list')
                .then(({data}) => {
                    this.countries = data.data;
                    this.country = this.dataCountry ? this.dataCountry : this.getShengen()
                    this.countries.splice(0, 0, this.mainCountries[0],);
                    this.countries.splice(5, 0, this.mainCountries[1])
                });

        },
        LetterNumMask(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z0-9 ]+$/.test(char)) {
                this.LetterMaskError = false;
                return true;
            } // Match with regex
            else {
                this.LetterMaskError = true;
                e.preventDefault();
            }
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
